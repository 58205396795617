'use strict';

/**
 * @ngdoc directive
 * @name jarb-input.directive:jarbInput
 * @description
 *   jarbInput is the companion angularjs directive for the Java Repository Bridge (JaRB).
 *   JaRB is a Java project that maps database constraints to Java classes. This directive
 *   uses that same information to render <input> fields with Angular constraints directives.
 *   Such as ng-required, ng-pattern, ng-maxlength and ng-minlength.
 *
 *   The constraints come from the 'jarbInputStore' factory, the factory must be filled for
 *   this jarbInput directive to work. When the 'jarbInputStore' is filled every jarbInput
 *   directive will rerender. If the store is already filled the directive will instantly
 *   render. So it is best to fill the store as soon as possible.
 *
 *   This directive 're-compiles' itself after the store is filled. It needs to recompile in order
 *   for added constraints directives (ng-required etc) to work. That's why this directive is
 *   terminal: so other directives stop, and has a high priority: so it is the first directive run on
 *   the element.
 *
 *   You are responsible for filling the 'jarbInputStore' yourself.
 *
 *   Usage example:
 *
 *   Given:
 *
 *   <input ng-model="name" jarb-input="User.name">
 *
 *    With the jarbInputStore.constraints:
 *
 *    "User": {
 *      "name": {
 *        "javaType":"java.lang.String",
 *        "types":["text"],
 *        "required":true,
 *        "minimumLength":null,
 *        "maximumLength":50,
 *        "fractionLength":null,
 *        "radix":null,
 *        "pattern":null,
 *        "min":null,
 *        "max":null,
 *        "name":"name"
 *      },
 *    }
 *
 *   Generates:
 *
 *   <input ng-model="name" class="<valiation_classes>" type="text" name="name" ng-required="true" ng-maxlength="50" required="required">
 */
angular.module('jarb-input', [])
  .directive('jarbInput', function(jarbInputStore, jarbInputSelector, $compile, jarbRegexProvider) {

    return {
      restrict: 'A',
      link: link,
      terminal: true, // We want jarb-input this to be the last directive that is run on this element.
      priority: 9000  // We want jarb-input to be the first directive that is run on this element.
    };

    /**
     * @description
     *  Adds attributes based on JaRB contraints from the back-end.
     *  The attributes are: ng-required, ng-maxlength, ng-minlength, ng-pattern.
     * @param  {scope} The scope the directive works on
     * @param  {element} The element the directive works on.
     */
    function link(scope, element, attrs) {
      var validator = attrs.jarbInput;
      var jarbConstraints = jarbInputStore.getConstraints();

      // When the constraints are null, wait until the contraints are filled.
      if (jarbConstraints === null) {
        jarbInputStore.onConstraintsChanged(function(constraints) {
          addAttributes(scope, element, validator, constraints);
        });
      } else {
        addAttributes(scope, element, validator, jarbConstraints);
      }
    }

    /**
     * @description Adds attributes based on JaRB constraints from the back-end.
     * @param  {scope} The scope the directive works on
     * @param  {element} The element the directive works on.
     */
    function addAttributes(scope, element, validator, constraints) {
      // Get the validation rules for the validator.
      var validationRules = jarbInputSelector.validationRulesFor(validator, constraints, function(failure) {
        throw errorMsg(validator, failure);
      });

      var formInput = element.find('.form-input');
      if (formInput.length === 0) {
        formInput = element;
        element.addClass('form-input');
      }

      if (formInput.is('input')) {
        var type = mostSpecificInputTypeFor(validationRules.types, validationRules.javaType);
        formInput.attr('type', type);
      }

      if (!formInput.attr('name')) {
        formInput.attr('name', validationRules.name);
      }

      if (validationRules.required) {
        formInput.attr('ng-required', true);
      }

      if (validationRules.javaType === 'java.lang.String') {
        if (validationRules.maximumLength) {
          formInput.attr('ng-maxlength', validationRules.maximumLength);
        }
        if (validationRules.minimumLength) {
          formInput.attr('ng-minlength', validationRules.minimumLength);
        }
        addPatternAttribute(type, validationRules, formInput);
      }

      // remove the attribute to avoid indefinite loop, otherwise it keeps recusively compiling 'jarb-input'
      element.removeAttr('jarb-input');

      /*
        Re-compile the element, without the jarb-input directive, and this time with all
        constraint directives, such as ng-required, that jarb-input added based on the
        validation rules that were found in the store.
      */
      $compile(element.parent())(scope);
    }

    /**
     * @description Adds the 'ng-pattern' directive to the element when the type is 'number'.
     * @param {type} The <input> type for the element.
     * @param {element} The HTML element which may get a ng-pattern attribute.
     */
    function addPatternAttribute(type, validationRules, element) {
      var pattern = false;

      if (type === 'number' && validationRules.fractionLength > 0) { //eslint-disable-line angular/typecheck-number
        pattern = jarbRegexProvider.fractionNumberRegex(validationRules.fractionLength);
      } else if (type === 'number') { //eslint-disable-line angular/typecheck-number
        pattern = jarbRegexProvider.numberRegex();
      }

      if (pattern) {
        element.attr('ng-pattern', pattern);
      }
    }

   /**
     * @description
     *  Finds the most specific <input> type for the types parameter. For example if
     *  types is ['email', 'text'] the function returns 'email' because 'email'
     *  is the most specific input type. If nothing is found returns 'text'.
     * @param  {Array<string>} The types you want the closest type for.
     * @return {string} The closest <input> type, based on the types parameter.
     */
    function mostSpecificInputTypeFor(types, javaType) {
      // List of <input> types sorted on most specific first. In es6 this would be a const.
      var inputTypes = ['color', 'datetime-local', 'datetime', 'month', 'week', 'date', 'time', 'email', 'tel', 'number', 'url', 'password', 'file', 'image', 'text'];

      if (javaType === 'boolean' || javaType === 'Boolean') {
        return 'checkbox';
      } else if (types.length === 0) {
        return 'text';
      } else {
        var closestMatchingType = _(inputTypes).intersection(types).first();
        if (closestMatchingType) {
          return closestMatchingType;
        }
      }
      return 'text';
    }

    /**
     * @description Prefixes the 'message' parameter with a the default error message start.
     * @param  {string} The validator that gave the error.
     * @param  {string} The message you want to prefix with a error message start.
     * @return {string} The message prefixed with the default error message start.
     */
    function errorMsg(validator, message) {
      return 'jarbInput validator("' + validator + '") exception: ' + message;
    }
  });